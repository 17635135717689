import { RelayProvider } from "@polymarket/gsn-provider";
import { GasStationPriceOption } from "@polymarket/gsn-provider/lib/types";
import { Web3Provider } from "@ethersproject/providers";

import {
    CHAIN_CONFIG,
    GSN_HUB,
    RELAYERS,
} from "../helpers/constants";

export const getRelayProvider = (magic) => {
    if (CHAIN_CONFIG.chainId === 1) {
        return new Web3Provider(magic.rpcProvider)
    }

    return new RelayProvider([magic.rpcProvider], RELAYERS, GSN_HUB, {
        allowedRelayNonceGap: 50,
        verbose: true,
        /**
         * RelayProvider tries to fetch the gas price from https://gasstation-mainnet.matic.network/.
         * Gas price options include 'Low', 'Mid', 'High', and 'Highest'. Default is 'High'.
         * If that request fails, the provider will multiply the network gas price by (`gaspriceFactorPercent` + 100) / 100
         */
        gasPriceOption: GasStationPriceOption.High,
        fallbackGasPricePercentMultiplier: 100,
        ensureTxMinedTimeout: 10000, // 10 seconds
    });
};
