import { Contract } from "@ethersproject/contracts";

import {
  erc20TransferTransaction,
  ethTransferTransaction,
} from "@polymarket/sdk";
import proxyWalletFactoryArtifact from "@polymarket/predictions-sol/build/ProxyWalletFactory.json";
import { JsonRpcProvider } from "@ethersproject/providers";
import fromEthers from "@polymarket/providers/from-ethers";
import toGSNNoLogProvider from "@polymarket/providers/gsn-nolog";
import { CHAIN_CONFIG, PROXY_WALLET_FACTORY_ADDRESS } from "./constants";
import {
  getContractProxyKit,
  getCpkOperation,
} from "../helpers/contractProxyKit";
import { BigNumber } from "@ethersproject/bignumber";
const ERC_20_ABI = [
  "function balanceOf(address owner) view returns (uint256)",
  "function transfer(address to, uint amount) returns (boolean)",
];

const coerceToSigner = (providerOrSigner) => {
  try {
    return providerOrSigner.getSigner();
  } catch (e) {
    console.log(e);
    return providerOrSigner;
  }
};

const getMainnetProxyWalletFactory = (provider) => {
  const logProvider = toGSNNoLogProvider(
    provider,
    fromEthers(new JsonRpcProvider(CHAIN_CONFIG.rpcUrl))
  ).asEthers();

  const proxyWalletFactory = new Contract(
    PROXY_WALLET_FACTORY_ADDRESS,
    proxyWalletFactoryArtifact.abi,
    coerceToSigner(logProvider)
  );

  return proxyWalletFactory;
};

const getProxyWalletFactory = (provider) => {
  console.log(PROXY_WALLET_FACTORY_ADDRESS);
  const proxyWalletFactory = new Contract(
    PROXY_WALLET_FACTORY_ADDRESS,
    proxyWalletFactoryArtifact.abi,
    coerceToSigner(provider)
  );

  return proxyWalletFactory;
};

export const getERC20Balance = async (
  tokenAddress,
  provider,
  userAddress,
  decimals
) => {
  const contract = new Contract(tokenAddress, ERC_20_ABI, provider);
  const balance = await contract.balanceOf(userAddress);
  return BigNumber.from(balance);
};

export const sendERC20 = async (tokenAddress, provider, to, amount) => {
  console.log("trying to send erc20");
  const proxyWalletFactory = getProxyWalletFactory(provider);
  console.log("got proxy wallet");
  const erc20Send = erc20TransferTransaction(tokenAddress, to, amount);
  console.log("erc20Send", erc20Send);
  const tx = proxyWalletFactory.proxy([erc20Send]);
  console.log("tx", tx);
  return tx;
};

export const sendERC20Mainnet = async (tokenAddress, provider, to, amount) => {
  console.log(`trying to send erc20 using provider`, {
    provider,
    tokenAddress,
    to,
    amount,
  });
  const proxyWalletFactory = getProxyWalletFactory(provider);
  console.log("got proxy wallet", { proxyWalletFactory });
  const erc20Send = erc20TransferTransaction(tokenAddress, to, amount);
  console.log("erc20Send", { erc20Send });
  const tx = await proxyWalletFactory.proxy([erc20Send]);
  console.log("tx", { tx });
  return tx;
};

export const sendETH = async (provider, to, amount) => {
  const proxyWalletFactory = getProxyWalletFactory(provider);
  const ethSend = ethTransferTransaction(to, amount);
  console.log("ethSend", ethSend);
  const tx = proxyWalletFactory.proxy([ethSend]);
  console.log("tx", tx);
  return tx;
};

export const sendETHMainnet = async (provider, to, amount) => {
  const proxyWalletFactory = getProxyWalletFactory(provider);
  const ethSend = ethTransferTransaction(to, amount);
  console.log("ethSend", ethSend);
  const tx = proxyWalletFactory.proxy([ethSend]);
  console.log("tx", tx);
  return tx;
};

export const sendERC20Safe = async (tokenAddress, provider, to, amount) => {
  const cpk = await getContractProxyKit(provider);
  const erc20Send = erc20TransferTransaction(tokenAddress, to, amount);
  const cpkTxs = [erc20Send].map((tx) => ({
    to: tx.to,
    data: tx.data,
    value: tx.value,
    operation: getCpkOperation(tx.typeCode),
  }));
  let tx;
  try {
    tx = await cpk.execTransactions(cpkTxs);
  } catch (err) {
    console.log(err);
  }
  console.log("tx", tx);
  return tx;
};
export const sendETHSafe = async (provider, to, amount) => {
  const cpk = await getContractProxyKit(provider);
  const ethSend = ethTransferTransaction(to, amount);
  console.log("ethSend", ethSend);
  const tx = await cpk.execTransactions([ethSend]);
  console.log("tx", tx);
  const result = await tx.wait();
  return result;
};
