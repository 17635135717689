export const MAGIC_KEY =
  process.env.REACT_APP_MAGIC_KEY || "pk_live_99ABD23F9F1C8266";

export const AUTH_WEBSITE =
  process.env.REACT_APP_AUTH_WEBSITE || "https://auth.polymarket.com";

/*
 * An environment is made up of the following values:
 *
 * mainnetNetwork / maticNetwork: The network objects as required for initialising Magic SDK
 * See: https://docs.magic.link/client-sdk/web#create-an-sdk-instance
 *
 * subgraphUrl: URL of a graph node tracking the Polymarket contracts on maticNetwork.
 * strapiUrl: URL of a strapi server which is tracking the Polymarket subgraph for maticNetwork.
 *
 * maticUsdc / maticUsdc: Addresses of the USDC token on Matic / Mainnet.
 * See: https://docs.matic.network/docs/develop/network-details/mapped-token
 *
 * maticBridge: An object containing the addresses on the main chainused to deposit funds into Matic.
 * See: https://github.com/maticnetwork/static/blob/master/network/mainnet/v1/index.json
 * See: https://github.com/maticnetwork/static/blob/master/network/testnet/mumbai/index.json
 *
 */
const environments = {
  mainnet: {
    mainnetNetwork: {
      rpcUrl:
        "https://eth-mainnet.g.alchemy.com/v2/3oM1CTm8lcxCXE45OVYFcxkBR2nj7Xue",
      chainId: 1,
    },
    rinkebyNetwork: {
      rpcUrl:
        "https://eth-rinkeby.alchemyapi.io/v2/NgtINE6Xi1e0mctOVjLxygjfGrfoLejW",
      chainId: 4,
    },
    maticNetwork: {
      rpcUrl:
        "https://polygon-mainnet.g.alchemy.com/v2/wwBxOtevEUo4PsWHdswCdU_xC7vF3pOk",
      chainId: 137,
    },
    strapiUrl: "https://strapi-matic.poly.market",
    subgraphUrl:
      "https://api.thegraph.com/subgraphs/name/polymarket/matic-markets-5",
    maticUsdc: "0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174",
    mainnetUsdc: "0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48",
    maticBridge: {
      rootChainManagerProxy: "0xA0c68C638235ee32657e8f720a23ceC1bFc77C77",
      erc20PredicateProxy: "0x40ec5B33f54e0E8A33A975908C5BA1c14e5BbbDf",
    },
    conditionalTokens: "0x4D97DCd97eC945f40cF65F87097ACe5EA0476045",
    proxyWalletFactory: "0xaB45c5A4B0c941a2F231C04C3f49182e1A254052",
    gsnHub: "0xd216153c06e857cd7f72665e0af1d7d82172f494",
    relayers: [
      {
        relayerAddress: "0xae700edfd9ab986395f3999fe11177b9903a52f1",
        url: "https://matic-gsn-v2-1.polymarket.io",
        transactionFee: 1,
      },
      {
        relayerAddress: "0x31cd1777a93046ede07a5bef370e633469da71c0",
        url: "https://matic-gsn-v2-2.polymarket.io",
        transactionFee: 1,
      },
      {
        relayerAddress: "0x0a16ff4c4e2ecd65e178e5a793f3297312844a62",
        url: "https://matic-gsn-v2-3.polymarket.io",
        transactionFee: 1,
      },
    ],
  },
  mumbai: {
    mainnetNetwork: {
      rpcUrl: "https://goerli.infura.io/v3/562ab40b527f4075bc243e16524ac36f",
      chainId: 5,
    },
    maticNetwork: {
      rpcUrl:
        "https://polygon-mumbai.infura.io/v3/562ab40b527f4075bc243e16524ac36f",
      chainId: 80001,
    },
    strapiUrl: "",
    subgraphUrl:
      "https://api.mumbai-graph.matic.today/subgraphs/name/TokenUnion/polymarket",
    maticUsdc: "0xfe4F5145f6e09952a5ba9e956ED0C25e3Fa4c7F1",
    mainnetUsdc: "0x655F2166b0709cd575202630952D71E2bB0d61Af",
    maticBridge: {
      rootChainManagerProxy: "0xBbD7cBFA79faee899Eaf900F13C9065bF03B1A74",
      erc20PredicateProxy: "0xdD6596F2029e6233DEFfaCa316e6A95217d4Dc34",
    },
    conditionalTokens: "0x7D8610E9567d2a6C9FBf66a5A13E9Ba8bb120d43",
    proxyWalletFactory: "0xaB45c5A4B0c941a2F231C04C3f49182e1A254052",
    gsnHub: "0xd216153c06e857cd7f72665e0af1d7d82172f494",
    relayers: [
      {
        relayerAddress: "0x94385591c282b9BDEf577917D86517B6DE4c1F65",
        url: "https://relayer-test.herokuapp.com",
        transactionFee: 0,
      },
    ],
  },
};

// Pull default config for environment from `environments`.
// Each individual value can then be further overridden if necessary

const validEnvironments = Object.keys(environments);
if (!validEnvironments.includes(process.env.REACT_APP_ENVIRONMENT)) {
  throw new Error(
    `Invalid environment. Please select one of ${validEnvironments}`
  );
}
export const ENVIRONMENT = process.env.REACT_APP_ENVIRONMENT;
export const CHAIN_NAME = process.env.REACT_APP_CHAIN;

if (CHAIN_NAME !== "matic" && CHAIN_NAME !== "mainnet") {
  throw new Error(
    `Must specify either "matic" or "mainnet" as REACT_APP_CHAIN but instead received ${CHAIN_NAME}`
  );
}

const selectedEnvironment = environments[process.env.REACT_APP_ENVIRONMENT];
export const CONDITIONAL_TOKENS_ADDRESS = selectedEnvironment.conditionalTokens;

export const CHAIN_CONFIG =
  CHAIN_NAME === "mainnet"
    ? selectedEnvironment.mainnetNetwork
    : selectedEnvironment.maticNetwork;

export const SUBGRAPH_URL =
  process.env.REACT_APP_SUBGRAPH_URL || selectedEnvironment.subgraphUrl;
export const MATIC_BRIDGE = selectedEnvironment.maticBridge;
export const USDC_ADDRESS =
  process.env.REACT_APP_MATIC_USDC || selectedEnvironment.maticUsdc;
export const MAINNET_USDC_ADDRESS =
  process.env.REACT_APP_MAINNET_USDC || selectedEnvironment.mainnetUsdc;
export const PROXY_WALLET_FACTORY_ADDRESS =
  process.env.PROXY_WALLET_FACTORY_ADDRESS ||
  selectedEnvironment.proxyWalletFactory;
export const MATIC_GAS_PRICE = 2500000000;
export const GSN_HUB = selectedEnvironment.gsnHub;
export const MATIC_MAINNET_CHAINID = environments.mainnet.maticNetwork.chainId;
export const RELAYERS = selectedEnvironment.relayers;

export const CHAIN_CURRENCY =
  CHAIN_NAME === "mainnet"
    ? {
        name: "Ethereum",
        symbol: "ETH",
        decimals: 18,
        chainId: 1,
        logoURI:
          "https://polymarket-static.s3.us-east-2.amazonaws.com/ethereum.webp",
      }
    : {
        name: "Matic",
        symbol: "MATIC",
        decimals: 18,
        chainId: 137,
        logoURI:
          "https://tokens.1inch.exchange/0x7d1afa7b718fb893db30a3abc0cfc608aacfebb0.png",
      };
