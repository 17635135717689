import { useUserInfo } from './context/UserContext'

import SendModal from './components/SendModal'
import MMSendModal from './components/MMSendModal'

import LoginForm from './components/LoginModal'

import polyLogo from './polymarket.svg'

import './App.css'



function App() {
  const user = useUserInfo();




  return (
    <div className="App">
      <div className="App_Container">
        <img src={polyLogo} />
        <h2>Recover your Tokens</h2>
        {user && user.address && !user.isMetamask &&
        
            <SendModal />
            
          
        }
           {user && user.isMetamask &&
  
          <MMSendModal/>
       
           }
        {!user &&
          <LoginForm />
        }
      </div>
    </div>
  );
}

export default App;
