import { useState, useEffect } from "react";

import MaticTokenList from "../helpers/maticTokenList";
import { CHAIN_NAME, ENVIRONMENT } from "../helpers/constants";

// NOTE: for matic token list in the future, can switch to using:
// https://api-polygon-tokens.polygon.technology/tokenlists/allTokens.tokenlist.json

export default function useTokenList() {
  const [tokens, setTokens] = useState([]);

  useEffect(() => {
    const fetchTokens = async () => {
      try {
        const res = await fetch(
          "https://tokens.coingecko.com/uniswap/all.json"
        );
        const data = await res.json();

        const tokenAddressToMoveToFront = '0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48';

        // MOVE USDC to FRONT
        const tokenIndex = data.tokens.findIndex(token => token.address === tokenAddressToMoveToFront);

        if (tokenIndex > -1) {
            const [tokenToMove] = data.tokens.splice(tokenIndex, 1);
            data.tokens.unshift(tokenToMove);
        }
        setTokens(data.tokens);
      } catch (err) {
        setTokens([]);
      }
    };

    if (CHAIN_NAME === "mainnet" && ENVIRONMENT === "mainnet") {
      fetchTokens();
    } else if (CHAIN_NAME === "mainnet" && ENVIRONMENT === "mumbai") {
      setTokens([
        {
          name: "USDC",
          address: "0x9DA9Bc12b19b22d7C55798F722A1B6747AE9A710",
          decimals: 6,
        },
      ]);
    } else {
      // NOTE: for matic token list in the future, can switch to using:
      // https://api-polygon-tokens.polygon.technology/tokenlists/allTokens.tokenlist.json
      setTokens(MaticTokenList);
    }
  }, []);

  return tokens;
}
